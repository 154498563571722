import { mapState } from "vuex";
// 导入api接口
import {
  getPrices, //获取产品价格接口
  getStocks, //获取产品库存接口
  getChannelPrice, //获取DMS产品价格接口
  getChannelStock, //获取DMS产品库存接口
  queryCustBrowseRecord //商家浏览记录列表接口
} from "@/utils/api.js";
// import util from "../product/components/util";
import sortBar from "./components/SortBar";
// import { productSearch } from "./api.js"; // 导入api接口
import { getFavori } from "./api";
import CardItemThree from "./components/CardItemThree.vue";
export default {
  name: "Home",
  components: {
    sortBar,
    CardItemThree
  },
  data() {
    return {
      nolistImg: require("@/assets/order/noList.png"),
      windowList: [],
      sortList: [
        // {
        //   name: "综合",
        //   sortStatus: 2, //0默认 1降 2升序
        // },
        {
          name: "上架时间",
          sortName: "baseProductInfo.ssTime",
          sortStatus: 1 //0默认 1降 2升序
        }
        // {
        //   name: "销量",
        //   sortStatus: 0, //0默认 1降 2升序
        // },
        // {
        //   name: "价格",
        //   sortStatus: 0, //0默认 1降 2升序
        // },
      ],
      dataList: [],
      total: 0, //数据总量

      historyList: [], //浏览记录list
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/favorites",
          name: "favorites",
          title: "收藏夹"
        }
      ],
      param: {
        sortName: "baseProductInfo.ssTime", //排序字段，sale销量，onlineDate上架时间
        sortType: "desc", //排序类型，asc正序 或 desc倒序
        pageNo: 1, //页码
        pageSize: 12 //每页条数
      },
      pageLoadFlag: false, //列表数据load标志
      listType: "", //海信采购2渠道采购1
      isChange: false //筛选条件切换 路由未变
    };
  },
  watch: {
    $route: function() {
      this.param.catalogId = this.$route.query.catalogId
        ? this.$route.query.catalogId
        : "";
      this.param.productId = this.$route.query.productId
        ? this.$route.query.productId
        : "";
      this.param.keyword = this.$route.query.keyword
        ? this.$route.query.keyword
        : "";
      // this.param.type = this.$route.query.type ? this.$route.query.type : "2";
      if (this.param.catalogId || this.param.productId) {
        // this.param.pageNo = 1;
      } else {
        //去除最后的产品项
        this.breadcrumbData = this.breadcrumbData.filter((e, idx) => {
          return idx < 2;
        });
      }
      this.isChange = true;
      this.param.pageNo = 1;
      // this.getProductSearch();
      //请求筛选公共接口 获取筛选数据
      this.$store.dispatch("filtrate/systemConfigFormItem").then(() => {
        this.isChange = false;
      });
      // //改变面包屑
      // let breadcrumbLast = {
      //   title: this.$route.query.tagNameL1
      //     ? this.$route.query.tagNameL2
      //       ? this.$route.query.tagNameL1 + "-" + this.$route.query.tagNameL2
      //       : this.$route.query.tagNameL1
      //     : "",
      // };
      let breadcrumbLast = {
        title: this.$route.query.tagNameL1 ? this.$route.query.tagNameL1 : ""
      };
      if (breadcrumbLast.title) {
        this.$set(this.breadcrumbData, 2, breadcrumbLast);
      }
    },
    filterLoadFlag() {
      // if(this.filterLoadFlag){
      // }
    }
  },
  computed: {
    ...mapState({
      productImgBaseUrl: state =>
        state.user.productImgBaseUrl
          ? state.user.productImgBaseUrl
          : localStorage.getItem("productImgBaseUrl"),
      //处理后的筛选数据list
      filList: state => state.filtrate.filList,
      //筛选组件加载loadding
      filterLoadFlag: state => state.filtrate.isLoading,
      //用于查询列表的筛选配置字段
      filterConfigStr: state => state.filtrate.filterConfigStr
    })
  },
  created() {
    // if (this.$route.query.tagName) {
    //   this.param.keyword = this.$route.query.tagName;
    // }
    if (this.$route.query.catalogId) {
      this.param.catalogId = this.$route.query.catalogId;
    }
    if (this.$route.query.productId) {
      this.param.productId = this.$route.query.productId;
    }
    this.param.keyword = this.$route.query.keyword
      ? this.$route.query.keyword
      : "";
    let breadcrumbLast = {
      title: this.$route.query.tagNameL1 ? this.$route.query.tagNameL1 : ""
    };
    if (breadcrumbLast.title) {
      this.$set(this.breadcrumbData, 2, breadcrumbLast);
    }
  },
  mounted() {
    //筛选后续操作的入参 加后台已定义的 formCode 便于筛选后查询
    this.param = {
      ...this.param,
      formCode: this.filterConfigStr
        ? this.filterConfigStr +
          (this.$route.query.catalogId ? this.$route.query.catalogId : "")
        : ""
    };
  },
  methods: {
    // 跳转页面
    handlePageChange(page) {
      this.param.pageNo = page;
      this.getProductSearch();
    },

    onFilterChange(checkedObj) {
      //重置筛选条件 将新条件融合进去
      // this.setNullParams();

      Object.assign(this.param, checkedObj[0]);

      // 查询产品列表
      this.param.pageNo = 1;
      this.getProductSearch();
    },
    //获取价格
    getPrices(idx, param, code) {
      getPrices(param)
        .then(pricesRes => {
          // 源数据赋值库存
          // if (pricesRes.statusText == "OK") {
          // 划线价格（提货价）
          this.$set(
            code == "history" ? this.historyList[idx] : this.dataList[idx],
            "standardPrice",
            pricesRes.data[0].standardPrice
              ? pricesRes.data[0].standardPrice.toFixed(2)
              : null
          );
          // 标准价格（正常价）
          this.$set(
            code == "history" ? this.historyList[idx] : this.dataList[idx],
            "price",
            pricesRes.data[0].price ? pricesRes.data[0].price.toFixed(2) : null
          );
          this.$set(
            code == "history" ? this.historyList[idx] : this.dataList[idx],
            "retailPrice",
            pricesRes.data[0].retailPrice
              ? pricesRes.data[0].retailPrice.toFixed(2)
              : null
          );
          this.$forceUpdate();
          // }
        })
        .catch(error => {
          console.log(error);
        });
    },
    getChannelPrice(idx, param, code) {
      getChannelPrice(param)
        .then(pricesRes => {
          // if(pricesRes && pricesRes.data.code == 0){
          // 划线价格（提货价）
          this.$set(
            code == "history" ? this.historyList[idx] : this.dataList[idx],
            "standardPrice",
            pricesRes.data.list[0].standardPrice
              ? pricesRes.data.list[0].standardPrice.toFixed(2)
              : null
          );
          // 标准价格（正常价）
          this.$set(
            code == "history" ? this.historyList[idx] : this.dataList[idx],
            "price",
            pricesRes.data.list[0].retailPrice
              ? pricesRes.data.list[0].retailPrice.toFixed(2)
              : null
          );
          //建议零售价
          debugger;
          this.$set(
            code == "history" ? this.historyList[idx] : this.dataList[idx],
            "retailPrice",
            pricesRes.data.list[0].retailPrice
              ? Number(pricesRes.data.list[0].retailPrice)
                  .toFixed(2)
                  .toString()
              : null
          );

          this.$forceUpdate();
        })
        .catch(error => {
          console.log(error);
        });
    },
    //获取库存
    getStocks(idx, param, code) {
      // 根据产品列表获取库存
      getStocks(param)
        .then(stocksRes => {
          // 源数据赋值库存
          // if (stocksRes.statusText == "OK") {
          this.$set(
            code == "history" ? this.historyList[idx] : this.dataList[idx],
            "inventory",
            stocksRes.data[0].inventory
          );
          this.$set(
            code == "history" ? this.historyList[idx] : this.dataList[idx],
            "sharedInv",
            stocksRes.data[0].sharedInv
          );
          this.$forceUpdate();
          // }
        })
        .catch(error => {
          console.log(error);
        });
    },
    //获取库存
    getChannelStock(idx, param, code) {
      // 根据产品列表获取库存
      getChannelStock(param)
        .then(stocksRes => {
          let res = JSON.parse(stocksRes.data);
          // if(stocksRes.status == 200 && res.code == 0){
          // 库存
          this.$set(
            code == "history" ? this.historyList[idx] : this.dataList[idx],
            "invQty",
            res.data[0].invQty
          );

          this.$set(
            code == "history" ? this.historyList[idx] : this.dataList[idx],
            "gicInvQty",
            res.data[0].gicInvQty
          );
          // }

          this.$forceUpdate();
        })
        .catch(error => {
          console.log(error);
        });
    },

    async getProductSearch() {
      this.pageLoadFlag = true;

      try {
        const res = await getFavori(this.param);
        if (
          res &&
          res.data &&
          res.data.oftenProductList &&
          res.data.oftenProductList.content
        ) {
          const oftenProductList = res.data.oftenProductList.content;
          oftenProductList.forEach(item => {
            item.often = "Y";
          });
          // this.total = res.data.totalRows;
          this.pageLoadFlag = false;
          this.dataList = oftenProductList;
          this.total = res.data.oftenProductList.totalRows;

          if (res && oftenProductList && oftenProductList.length > 0) {
            for (const idx in oftenProductList) {
              if (oftenProductList[idx].isFenXiao == "N") {
                let param = {
                  code: oftenProductList[idx].productCode,
                  orgId: oftenProductList[idx].orgId,
                  orgCode: oftenProductList[idx].orgCode
                };
                this.getPrices(idx, param);
                this.getStocks(idx, param);
              } else if (oftenProductList[idx].isFenXiao == "Y") {
                let paramDmsPrice = {
                  orgId: oftenProductList[idx].orgId,
                  productId: oftenProductList[idx].productCode
                };
                let paramDmsStock = {
                  productCodes: [oftenProductList[idx].productCode],
                  supplierCode: oftenProductList[idx].agentCisCode
                };
                debugger;
                this.getChannelPrice(idx, paramDmsPrice);
                this.getChannelStock(idx, paramDmsStock);
              }
              //建议零售价
              // this.$set(
              //     this.dataList[idx],
              //     "retailPrice",
              //     this.dataList[idx].retailPriceL
              //         ? Number(this.dataList[idx].retailPriceL)
              //             .toFixed(2)
              //             .toString()
              //         : null
              // );
            }
          }
        } else {
          this.pageLoadFlag = false;
          this.dataList = [];
          this.total = 0;
        }
      } catch (error) {
        console.log(error);
        this.pageLoadFlag = false;
        this.dataList = [];
        this.total = 0;
      }
    },
    //排序调用
    sortFun(item) {
      this.param.sortName = item.sortName;
      this.param.sortType =
        item.sortStatus == 0 ? "" : item.sortStatus == 1 ? "desc" : "asc";
      this.getProductSearch();
    }
  }
};
