<!--
 * @Author: sqk
 * @Date: 2020-09-02 10:28:52
 * @LastEditTime: 2020-11-23 15:08:12
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\components\global\filtrate\Filtrate.vue
-->

<template>
  <div class="sort-bar-box clearfix">
    <ul>
      <li
        @click="sortFun(item, index)"
        v-for="(item, index) in data"
        :key="index"
        class="d-f-c"
        :class="
          item.sortStatus == 0 ? '' : item.sortStatus == 1 ? 'sort' : 'resort'
        "
      >
        <span>{{ item.name }}</span>
        <i class="iconfont icon-shangsheng"></i>
      </li>
      <!-- <li class="d-f-c">
        <span>销量</span>
        <i class="iconfont icon-shangsheng"></i>
      </li>
      <li class="d-f-c">
        <span>价格</span>
        <i class="iconfont icon-shangsheng"></i>
      </li> -->
    </ul>
    <div class="total-box">共搜索到 {{total}} 个商品</div>
  </div>
</template>

<script>
export default {
  name: "SortBar",
  data() {
    return {
      currentProvide:undefined
    };
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    total: {
      type: [String, Number],
      default: '0'
    },
  },
  mounted() {},
  methods: {
    sortFun(item, idx) {
      //需要emit
      for (let key in this.data) {
        if (key == idx) {
          continue;
        } else {
          this.data[key].sortStatus = 0;
        }
      }
      // if (item.sortStatus == 0) {
      //   item.sortStatus = 1;
      // } else if (item.sortStatus == 1) {
      //   item.sortStatus = 2;
      // } else {
      //   item.sortStatus = 0;
      // }
      
      if (item.sortStatus == 0) {
        if(item.name == '综合'){
          item.sortStatus = 1;
        }else if (item.name == '上架时间') {
          item.sortStatus = 1;
        }else if (item.name == '销量') {
          item.sortStatus = 1;
        }else if (item.name == '价格') {
          item.sortStatus = 2;
        }
      } else if (item.sortStatus == 1) {
        item.sortStatus = 2;
      } else {
        item.sortStatus = 1;
      }

      this.$emit('click',item);

    }
  }
};
</script>

<style lang="less" scoped>
.sort-bar-box {
  width: 1188px;
  height: 54px;
  margin: 0 auto;
  background-color: #fff;
  // padding-left: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ul {
    float: left;
    padding-right: 28px;
    li {
      height: 54px;
      line-height: 54px;
      text-align: center;
      padding: 0 6px;
      margin-left: 20px;
      float: left;
      cursor: pointer;
      transition: all 0.1s;
      &.sort {
        color: #00aaa6;
        .iconfont {
          visibility: visible;
          transform: rotate(180deg);
          color: #00aaa6;
        }
      }
      &.resort {
        color: #00aaa6;
        .iconfont {
          visibility: visible;
          color: #00aaa6;
        }
      }
      &:hover {
        color: #00aaa6;
        .iconfont {
          color: #00aaa6;
        }
      }
      span {
        display: inline;
      }
      .iconfont {
        font-size: 8px;
        margin-left: 6px;
        color: #777;
        visibility: hidden;
      }
    }
  }
  .total-box {
    font-size: 14px;
    color: #777777;
    height: 18px;
    line-height: 18px;
    border-left: 1px solid #eee;
    text-indent: 23px;
    float: left;
    // margin-top: 11px;
  }
}
</style>
